@import "../../../../../../style/mixin";

.step3ArticleWrap{
  width: 100%;
  height: calc(110vh);
  padding-top: 68px;

  @media screen and (max-width: 1450px) {
    height: 180vh;
  }
  @media screen and (min-width: 1450px) {
    height: 140vh;
  }
  .step3Buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 36px;
    gap: 24px;

    .myBtn{
      width: 193px;
      height: 58px;
      color: #50A1FF;

      border: 1px solid #50A1FF;

      background-color: inherit;
      border-radius: 27px;
      @include font("smb");
      font-size: 20px;
      cursor: pointer;
    }

    .saleFinger{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .fingerWrap{
        @include floating;
      }
    .saleBtn{
      width: 193px;
      height: 58px;
      color: #000000;
      @include shadow;

      border: 1px solid #50A1FF;

      background-color: #50A1FF;
      border-radius: 27px;
      @include font("smb");
      font-size: 20px;
      cursor: pointer;
    }
    }

  }

  .step3PlanetContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;

    .step3Header{
      font-size: 48px;
      color: #ffffff;
      @include font("bold");
      padding-left: 15%;
      padding-top: 2%;
    }
    
    .step3PlanetComponent{
      margin: 0 auto;
    }


    .step3User{
      display: flex;
      flex-direction: column;
      text-align: center;

      .userInfo{
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        .userName{
          @include font("smb");
          font-size: 30px;
          color: #ffffff;
        }
        .userId{
          @include font("mdm");
          font-size: 20px;
          color: rgba(255, 255, 255, 0.7);
        }

      }
        .userPaymentInfo{
          display: flex;
          flex-direction: column;
          gap: 10px;

          .paymentInfoWrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 20px;

            .infoItem1 {
              @include font("mdm");
              color: rgba(255, 255, 255, 0.5);
            }
            .infoItem2 {
              @include font("mdm");
              color: #ffffff;
            }
          }

          .paymentInfoWrap2 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 20px;

            .infoItem1 {
              @include font("mdm");
              color: rgba(255, 255, 255, 0.5);
            }
            .infoItem2 {
              @include font("mdm");
              color: #ffffff;
            }
          }
        }

    }
  }
}