@import "../../../../style/mixin";


.headerWrap{
  position: fixed;
  width: 100%;
  z-index: 15;
}
.headerArticle{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  background-color: #141414;

  padding-left: 30px;
  padding-right: 24px;

  .headerIcons{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    .headerPointer{
        position: relative;
      border-radius: 8px;
      @include shadow;
      .headerFinger1{
        position: absolute;
        left: 60px;
        z-index: 1;
        @include floating;
      }
    }
    .headerIconLogo{
      cursor: pointer;
      display: flex;
      overflow: hidden;
    }
  }
  .headerItems{
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 100%;
    align-items: center;
    @include font("smb");
    cursor: pointer;

    .headerPointer{
      position: relative;
      @include shadow;
      border-radius: 8px;

      .headerFinger2{
        position: absolute;
        left: 30px;
        z-index: 1;
        @include floating;
      }
      .headerFinger3{
        position: absolute;
        left: 10px;
        z-index: 1;
        @include floating;
      }
      .headerFinger4{
        position: absolute;
        left: 0px;
        z-index: 1;
        @include floating;
      }
    }

    .headerItemIcons{
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    .headerProfile{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .headerTextItems{
      display: flex;
      flex-direction: row;
      color: rgba(255, 255, 255, 0.7);;
      font-size: 18px;
      @media screen and (max-width: 1200px){
        font-size: 10px;
      }
      gap: 22px;

      & a{
        text-decoration: none;
        color: inherit;
      }
    }
  }

}
