@import "../../../../style/mixin";

.kyTrendsSection {
  width: 100%;
  height: 250vh;

  background-color: #121212;
  @media (max-width: 1440px) {
    height: 350vh;
  }

  .kyTrendsContentWrap {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;


    .kyTrendsTable{
      padding: 24px 64px 180px 64px;

      .kyTrendsTableTitle{
        color: #ffffff;
        @include font("bold");
        font-size: 30px;
        padding: 20px 0 24px 24px;
      }

      .kyTrendsTableContent{
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }

    .kyTrendsGraphWrap {
      padding: 0px 64px 120px 64px;

      .kyTrendsGraphTitle {
        color: #ffffff;
        @include font("bold");
        font-size: 30px;
        padding: 20px 0 24px 24px;
      }

      .kyTrendsGraphDetails {
        margin-top: 32px;
        width: 100%;
        height: 128px;
        background-color: #434343;
        border-radius: 8px;

        .kyTrendsGraphDetailsWrap {
          padding: 24px 64px 24px 64px;
          display: flex;
          flex-direction: row;
          gap: 216px;
          @media screen and (max-width: 1440px) {
            gap: 70px;
          }

          align-items: center;
          .kyTrendsGraphDetailsItem {
            display: flex;
            flex-direction: column;
            gap: 8px;


            .kyTrendsGraphDetailsItemTitle {
              @include font("mdm");
              color: rgba(255, 255, 255, 0.5);
            }

            .kyTrendsGraphDetailsItemValue {
              @include font("smb");
              color: #ffffff;
              font-size: 30px;
            }
          }
        }
        .kyTrendsGraphDetailsItem2 {
          display: flex;
          flex-direction: column;
          gap: 8px;
            .kyTrendsGraphDetailsItemTitle {
              @include font("mdm");
              color: rgba(255, 255, 255, 0.5);
            }
              .kyTrendsGraphDetailsItemValue {
                @include font("smb");
                color: #ffffff;
                font-size: 30px;
              }

          .kyTrendsGraphDetailsItemsWrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .kyTrendsGraphDetailsItempercent {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 10px;
              color: #85EFAC;
              @include font("mdm");
            }
          }
        }
      }

      .kyTrendsGraphCategories {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .kyTrendsGraphCategoryDay {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .kyTrendsGraphCategoryDayItems1 {
            width: 71px;
            height: 36px;
            border: 1px solid #50a1ff;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            @include font("mdm");
            cursor: pointer;
          }
          .kyTrendsGraphCategoryDayItems {
            width: 71px;
            height: 36px;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            @include font("mdm");
            cursor: pointer;
          }
        }

        .kyTrendsGraphCategoryMenu {
          display: flex;
          flex-direction: row;
          gap: 12px;

          .kyTrendsGraphCategoryMenuItems1 {
            @include font("smb");
            font-size: 20px;
            height: 56px;
            border-bottom: 1px solid #50a1ff;
            color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          .kyTrendsGraphCategoryMenuItems {
            @include font("smb");
            font-size: 20px;
            height: 56px;
            color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

    .kyTrendsStatsWrap{
      padding: 0px 64px 0px 64px;

      .kyTrendsStatsIcons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 32px;
        height: 20px;

        .kyTrendsStatsIconWrap{
          display: flex;
          flex-direction: row;

          .kyTrendsStatsIcon{
            color: rgba(255, 255, 255, 0.5);
            @include font("mdm");
            font-size: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
}
