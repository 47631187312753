@import "../../../../../../style/mixin";

.step4ArticleWrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 68px;

  height: 130vh;
  @media screen and (max-width: 1450px) {
    height: 180vh;
  }
  @media screen and (min-width: 1450px) {
    height: 150vh;
  }

  .step4Header {
    color: #ffffff;
    font-size: 48px;
    @include font("bold");
    padding-left: 15%;
    padding-top: 3%;
    @media screen and (max-width: 1200px) {
      padding-left: 5%;
      padding-top: 10%;
    }
  }

  .step4PlanetWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 15%;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      display: flex;
      flex-direction: column;
    }
  }

  .step4PlanetContainer {
    padding-top: 30px;
    width: 480px;
    @media screen and (max-width: 1200px) {
      margin: 0 auto;
    }

    .step4User {
      display: flex;
      flex-direction: column;

      padding-top: 36px;
      gap: 10px;

      .userName {
        @include font("smb");
        color: #ffffff;
        font-size: 30px;
        margin: 0 auto;
      }
      .userId {
        @include font("smb");
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
        margin: 0 auto;
      }
    }
  }
  .step4SaleButton {
    display: flex;
    justify-content: end;
    padding-right: 100px;
    padding-bottom: 100px;
    position: relative;

    .fingerWrap1{
      position: absolute;
      top: 65px;
      right: 180px;
      @include floating;
    }

    .saleButton {
      background-color: #7ab7ff;
      border: 1px solid #7ab7ff;
      width: 213px;
      height: 58px;
      border-radius: 27px;
      font-size: 20px;
      @include font("smb");
      cursor: pointer;
      @include shadow;
    }
  }
}
.step4Script {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  gap: 20px;
  @media screen and (max-width: 1200px) {
    padding-left: 5%;
  }

  .scriptHeader {
    color: #ffffff;
    font-size: 30px;
    @include font("bold");
  }

  .scriptContentText {
    color: #ffffff;
    font-size: 20px;
    @include font("bold");
    line-height: 32px;
  }

  .scriptPayment {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 25px;

    .scriptPaymentItem {
      display: flex;
      flex-direction: row;
      width: 420px;
      justify-content: space-between;

      .scriptPaymentTitle {
        @include font("smb");
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
      }

      .inputWrap {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        width: 200px;
        border: 1px solid #434343;
        border-radius: 8px;
        height: 32px;
        background-color: #272727;
        .colorInput {
          background-color: inherit;
          border: none;
          outline: none;
          color: #ffffff;
          cursor: pointer;
          padding-left: 10px;
        }
      }
    }
  }
}

.confirmModalContainer {
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .modalTextWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .modalHeader {
      @include font("bold");
      color: #ffffff;
      font-size: 20px;
      text-align: center;
    }

    .modalDescWrap {
      text-align: center;
      .modalDescription {
        @include font("smb");
        color: #ffffff;
      }
    }
  }

  .chainInfoWrap {
    background-color: #141414;
    border-radius: 8px;
    border: 1px solid #434343;
    .chainInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 16px 16px 16px;
      .polygonIcon {
        display: flex;
        felx-direction: row;
        gap: 10px;

        .polygonInfoWrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          color: #ffffff;
          :nth-child(2) {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      .connectedButton {
        width: 92px;
        height: 28px;
        background-color: #0e3619;
        border: 1px solid #166434;
        border-radius: 4px;
        color: #f2fdf5;
        @include font("smb");
        font-size: 12px;
      }
    }
  }

  .priceInfoWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .priceInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 36px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .feeInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 36px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    .paymentInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 36px;
    }
    .infoItem1 {
      @include font("mdm");
      font-size: 16px;
      color: #cccccc80;
    }

    .infoItem2 {
    }
    @include font("mdm");
    font-size: 16px;
    color: #ffffff;
  }

  .modalBtnWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    position: relative;

    .cancelBtn {
      width: 160px;
      height: 44px;
      color: #ffffff;
      background-color: inherit;
      border: 1px solid #434343;
      border-radius: 22px;
      cursor: pointer;
    }

    .confirmBtn {
      width: 160px;
      height: 44px;
      color: #000000;
      background-color: #7ab7ff;
      border: 1px solid #7ab7ff;
      border-radius: 22px;
      cursor: pointer;
      @include shadow;
      @include font("smb");
    }
    .finger2Wrap{
      position: absolute;
      @include floating2;
      bottom: -50px;
      right: -35%;
      transform: rotate(-90deg);
      @media screen and (min-height: 1250px) {
        top: 100%;
        right: 20%;
        @include floating;
      }
    }
  }
}

.completeModalContainer {
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .completeHeaderWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-size: 24px;
    @include font("bold");
    color: #ffffff;
  }

  .bodyWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .txWrap1 {
      display: flex;
      justify-content: space-between;
      height: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .txWrap2 {
      display: flex;
      justify-content: space-between;
    }
    .txItem1 {
      color: rgba(255, 255, 255, 0.5);
    }
    .txItem2 {
      color: #ffffff;
    }
  }
}

.loaderWrap {
  padding: 24px 24px 24px 24px;
  font-size: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
