@font-face {
  font-family: "nanumR";
  src: url(../public/font/nanum/NanumSquareR.ttf);
}
@font-face {
  font-family: "nanumL";
  src: url(../public/font/nanum/NanumSquareL.ttf);
}

@font-face {
  font-family: "nanumB";
  src: url(../public/font/nanum/NanumSquareB.ttf);
}

@font-face {
  font-family: "twig";
  src: url(../public/font/twig/Twig-Regular.ttf);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  height: 30%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #000000;
}

html, body, #root {
  margin: 0;
  padding: 0;
}
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
h1 {
  margin: 0;
}

hr{
  margin: 0;
}

/* 이하 생략 */



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */

/* Set core body defaults */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}


body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /*font-family: "nanumR";*/
  /*font-family: "nanumL";*/
  /*font-family: "nanumB";*/
  /*font-family: "twig";*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Box sizing rules */
}

/* Remove list styles on ul, ol elements */
ul[class],
ol[class] {
  list-style: none;
  margin: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
/* Natural flow and rhythm in articles by default */

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}



