@import "../../../../style/mixin";

.bitblueLockWrap {
  width: 100%;
  height: 100vh;
  background-image: url("../../../../public/image/kydemoback.jpeg");
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .inputArea {
    z-index: 2;

    .contactInfo{
      text-align: center;
      font-size: 12px;
      @include font("smb");
      padding-bottom: 20px;
      & a {
        text-decoration: none;
      }
    }
    .inputWrap {
      padding: 60px 60px 60px 60px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .continueBtn{
        width: 100%;
        height: 40px;
        background-color: #000000;
        border: none;
        color: #ffffff;
        @include font("smb");
        border-radius: 4px;
        cursor: pointer;
      }

      .passwordInputWrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .passwordMessage{
          color: red;
          font-size: 12px;
          @include font("nanumAcR");
          text-align: center;
          line-height: 20px;
        }
        .passwordMessageNone{
          height: 20px;
        }

        .passwordInput {
          width: 100%;
          height: 40px;
          border: none;
          padding: 0 16px 0 16px;
          background-color: rgba(236, 236, 236);
          outline: none;
        }
      }

      .inputTitleWrap {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .inputTitle {
          text-align: center;
          font-size: 24px;
          @include font("smb");
        }

        .inputDesc {
          text-align: center;
          font-size: 14px;
          @include font("mdm");
        }
      }

      .inputHeaderImage {
        margin: 0 auto;
        cursor: pointer;
      }
      .inputHeaderImage img {
        width: 200px;
        margin: 0 auto;
      }
    }
    width: 100%;
    max-width: 400px;
    border-radius: 4px;
    background-color: #ffffff;
    height: 500px;
  }
}
