@import "../../../style/mixin";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes floating {
  0% {
    transform: translate(-4px, -4px);
    transform: translateY(0);
  }
  50% {
    transform: translate(4px, 4px);
    transform: translateY(-10);
  }
  100% {
    transform: translate(-4px, -4px);
    transform: translateY(0);
  }
}

@keyframes floating4 {
  0% {
    transform: translate(-7px, -3px);
    transform: translateY(0);
  }
  50% {
    transform: translate(7px, 3px);
    transform: translateY(-30px);
  }
  100% {
    transform: translate(-7px, -3px);
    transform: translateY(0);
  }
}

@keyframes floating2 {
  0% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(-6px);
  }
}

@keyframes floating3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.loadingWrap{
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 20%;
  left: 35%;
}

.landingSection {
  background-image: url("../../../public/image/mainBG.jpeg");
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 600px) {
    background-image: url("../../../public/image/mobileBg.jpeg");
    height: calc(100vh + 50px);
  }
}
