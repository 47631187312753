@import "../../../../style/mixin";

.marketplaceArticle {
  width: 100%;
  height: 564px;
  background-image: url("../../../../public/image/marketplaceBack.jpeg");
  background-size: cover;
  padding-top:68px;
  @media screen and (max-width: 1200px) {
    height: 700px;
  }
  @media screen and (max-width: 900px) {
    height: 750px;
  }
  .commonHeader{
    position: sticky;
  }

  .marketplaceHeaderWrap {
    display: flex;
    flex-direction: row;
    padding: 72px 240px 72px 240px;
    gap: 64px;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      padding: 30px 100px 30px 100px;
      gap: 15px;
    }


    .marketplaceHeaderText {
      display: flex;
      flex-direction: column;
      padding-top: 32px;
      gap: 102px;
      @media screen and (max-width: 1200px) {
        flex-direction: row;
      }

      .marketplaceHeaderBtnWrap {
        display: flex;
        justify-content: end;

      }

      .marketplaceHeaderSearchBtn {
        width: 162px;
        height: 58px;
        border-radius: 27px;
        background-color: #7ab7ff;
        border: none;
        @include font("bold");
        font-size: 20px;
        @media screen and (max-width: 1200px) {
        margin-top: 120px;
      }
      }

      .marketplaceHeaderTextItem {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .marketplaceHeaderTextItemTitleWrap {
          line-height: 58px;
          font-size: 48px;
          color: #ffffff;
          @media screen and (max-width: 1600px) {
            font-size: 30px;
            line-height: 40px;
          }
          .marketplaceHeaderTextItemTitle {
            @include font("bold");
          }
        }
        .marketplaceHeaderTextItemDesc {
          @include font("smb");
          color: #ffffff;
          font-size: 20px;
          @media screen and (max-width: 1600px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.marketplaceBodyArticle {
  width: 100%;
  height: 2190px;
  @media screen and (max-width: 1600px) {
    height: 100%;
  }
  background-color: #121212;

  .marketplaceBody {
    width: 100%;
    max-width: 1680px;
    margin: 0 auto;

    .marketplaceNotabelPlanet {
      padding: 64px 120px 33px 120px;

      .marketplaceNotabelPlanetTitleWrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .marketplaceNotabelPlanetShowAll {
          display: flex;
          flex-direction: row;
          gap: 16px;
        }
        .marketplaceNotabelPlanetTitle {
          color: #ffffff;
          @include font("bold");
          font-size: 30px;
          padding-bottom: 25px;
        }
      }

      .marketplaceNotabelPlanetCardWrap {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: wrap;
      }
    }

    .marketplaceTopWrap {
      padding: 64px 120px 33px 120px;

      .marketplaceTopTitleWrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .marketplaceTop {
          color: #ffffff;
          @include font("bold");
          font-size: 30px;
          padding-bottom: 25px;
        }
      }

      .marketplaceAvatarContainer {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1200px) {
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }
  }

  .marketplaceCategories {
    display: flex;
    flex-direction: column;
    padding: 64px 80px 33px 80px;

    .marketplaceCategoriesHeader {
      display: flex;
      flex-direction: column;
      padding-bottom: 25px;

      .marketplaceCategoriesHeaderTitle {
        color: #ffffff;
        @include font("bold");
        font-size: 30px;
        padding-bottom: 25px;
        padding-left: 60px;
      }

      .marketplaceCategoriesHeaderItems{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .marketplaceCategoriesHeaderButtons{
          display: flex;
          flex-direction: row;
          gap: 8px;

          .marketplaceCategoriesHeaderButtonItemFocus{
            width: 100%;
            height: 44px;
            background-color: inherit;
            color: #ffffff;
            border: 2px solid rgb(55, 112, 200);
            border-radius: 8px;
          }
          .marketplaceCategoriesHeaderButtonItem{
            width: 100%;
            height: 44px;
            background-color: inherit;
            color: #ffffff;
            border-radius: 8px;
            border:none;
          }

        }
          .marketplaceCategoriesHeaderShowAll{
            display: flex;
            flex-direction: row;
            gap: 16px;
          }

      }
    }
        .marketplaceCategoriesCardWrap{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 33px;
        }
  }
}
