@import "../../../style/mixin";

.avatarWrap{
  width: 240px;
  height: 234px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: #121212;

  .avatarImage{
    > & img {
    border-radius: 100%;
    }
    margin: 0 auto;
  }

  .avatarTextWrap{
  .avatarTitle{
    @include font("mdm");
    color: rgba(255,255,255, 0.5);
    font-size: 16px;
    text-align: center;
  }

  .avatarName{
    @include font("bold");
    color: #ffffff;
    font-size: 24px;
    text-align: center;
  }
  }

}