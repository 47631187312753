
@font-face {
  font-family: "poppinsLight";
  src: url(../public/font/Poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: "poppinsMedium";
  src: url(../public/font/Poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: "poppinsRegular";
  src: url(../public/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "poppinsSemibold";
  src: url(../public/font/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: "poppinsbold";
  src: url(../public/font/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: "nanumR";
  src: url(../public/font/nanum/NanumSquareR.ttf);
}
@font-face {
  font-family: "nanumL";
  src: url(../public/font/nanum/NanumSquareL.ttf);
}

@font-face {
  font-family: "nanumB";
  src: url(../public/font/nanum/NanumSquareB.ttf);
}

@font-face {
  font-family: "nanumAcEb";
  src: url(../public/font/nanum/NanumSquare_acEB.ttf);
}

@font-face {
  font-family: "nanumAcR";
  src: url(../public/font/nanum/NanumSquare_acR.ttf);
}

@font-face {
  font-family: "nanumAcL";
  src: url(../public/font/nanum/NanumSquare_acL.ttf);
}


@font-face {
  font-family: "twig";
  src: url(../public/font/twig/Twig-Regular.ttf);
}

@font-face {
  font-family: "twigO";
  src: url(../public/font/twig/Twig-Outline.ttf);
}

@font-face {
  font-family: "twigI";
  src: url(../public/font/twig/Twig-Italic.ttf);
}

@font-face {
  font-family: "twigOI";
  src: url(../public/font/twig/Twig-OutlineItalic.ttf);
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes floating2 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}




@mixin font($bold) {
  @if $bold == "lgt" {
    font-family: "poppinsLight"
  }

  @if $bold == "mdm" {
    font-family: "poppinsMedium"
  }

  @if $bold == "rgr" {
    font-family: "poppinsRegular"
  }

  @if $bold == "smb" {
    font-family: "poppinsSemibold"
  }

  @if $bold == "bold" {
    font-family: "poppinsbold"
  }
  @if $bold == "nanumL" {
    font-family: "nanumL"
  }

  @if $bold == "nanumB" {
    font-family: "nanumB"
  }

  @if $bold == "nanumR" {
    font-family: "poppinsRegular"
  }
  @if $bold == "nanumAcL" {
    font-family: "nanumAcL"
  }
  @if $bold == "nanumAcR" {
    font-family: "nanumAcR"
  }
  @if $bold == "nanumAcEb" {
    font-family: "nanumAcEb"
  }

  @if $bold == "twig" {
    font-family: "twig"
  }
  @if $bold == "twigO" {
    font-family: "twigO"
  }
  @if $bold == "twigI" {
    font-family: "twigI"
  }
  @if $bold == "twigOI" {
    font-family: "twigOI"
  }

}

@mixin floating {
  animation: floating 2s ease-in-out infinite;
}

@mixin floating2 {
  animation: floating2 2s ease-in-out infinite;
}

@mixin languageMenuStyle  {
  width: 100%;
  height: 50%;
  background-color: #ffffff;
  padding-left: 25px;
  display: flex;
  align-items: center;
  transition: background-color 1s ease;
}

@mixin shadow {
  padding: 2px;
  box-shadow: 0px 0px 0px 4px #434343, 0px 0px 10px 8px #3b82f6;
  border: 3px solid #3b82f6;
}

