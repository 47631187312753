@import "../../../style/mixin";

.marketCardWrap{
  width: 256px;
  height: 378px;

  background-color: rgb(29,29,29);
  border-radius: 8px;

  .marketCardTextWrap{
    padding: 16px 16px 16px 16px;

    .marketCardImage{
      height: 242px;
    }

    .marketCardSeason{
      color: rgba(255,255,255, 0.5);
      @include font("smb");
      font-size: 16px;
    }
    .marketCardTitle{
      color: rgba(255,255,255);
      @include font("smb");
      font-size: 20px;
      white-space: nowrap;
      line-height: 32px;
    }

    .marketCardProfile{
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      color: rgba(255,255,255, 0.5);
      @include font("mdm");
      align-items: center;
      justify-content: end;
    }
  }
}