@import "../../../../style/mixin";

.headerWrap {
  width: 100%;
  background-color: inherit;
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 2;

  .headerContainer {
    display: flex;
    flex-direction: row;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1485px;
    margin: 0 auto;
    @media screen and (max-width: 1650px) {
      padding-left: 195px;
      padding-right: 195px;
    }
  }

  .headerLanguageChange {
    cursor: pointer;
    position: relative;

    .languageChangeWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .languageTranslate {
        color: #ffffff;
        @include font("nanumAcEB");
        cursor: pointer;
      }

    }

    .languageMenu {
      width: 213px;
      height: 100px;

      z-index: 3;
      height: 80px;
      width: 180px;
      border-radius: 5px;
      border: 1px solid #d2d2d2;
      position: absolute;
      right: 20px;
      position: absolute;
      right: 0;
      top: calc(100% + 10px);

      .isSelectedItem {
        @include languageMenuStyle;
        @include font("nanumAcEb");
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-size: 16px;
      }
      .isNotSelected {
        @include languageMenuStyle;
        @include font("nanumAcEb");
        font-size: 16px;
        background-color: rgb(214 212 222 / 95%);
      }

      .isKorSelectedItem {
        @include languageMenuStyle;
        @include font("nanumAcEb");
        border-bottom-left-radius: 5px;
        font-size: 16px;
        border-bottom-right-radius: 5px;
      }
      .isKorNotSelectedItem {
        @include languageMenuStyle;
        @include font("nanumAcEb");
        font-size: 16px;
        background-color: rgb(214 212 222 / 95%);
      }
    }
  }
  .headerLogoNav {
    display: flex;
    flex-direction: row;
    gap: 124px;
    align-items: center;
    cursor: pointer;

    .headerItems {
      display: flex;
      flex-direction: row;
      gap: 112px;
      cursor: pointer;
      :nth-child(3) {
        text-decoration: none;
      }

      .headerItem {
        @include font("nanumAcEb");
        color: #ffffff;

        &.not {
          opacity: 50%;
        }
      }
    }
  }
}
