@import "../../../style/mixin";

.kyMyPageSection {
  .kyMyPageBanner {
    width: 100%;
    height: 280px;
    background-image: url("../../../public/image/myPageBanner.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .kyMyPageBannerButtonWrap{
    padding: 0px 240px 16px 240px;
    height: 280px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .kyMyPageBannerButton{
      @include font("smb");
      height: 44px;
      width: 156px;
      border-radius: 22px;
      background-color: #434343;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }
  }

  .kyMyPageContent {
    background-color: #161616;
    height: 100%;
    padding-bottom: 100px;
    @media screen and (max-width: 1200px) {
      height: 130vh;
    }
    .kyMyPageContentWrap {
      display: flex;
      flex-direction: row;
      gap: 24px;
      padding: 0px 240px 0px 240px;
      justify-content: center;
      align-items: center;

      .kyMyPageContentRight{
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1550px) {
          margin-bottom: 300px;
        }

        .kyMyPageContentRightValue{
          width: 1056px;
          height: 160px;
          background-color: #292929;
          border-radius: 8px;
          margin-top: 64px;
          @media screen and (max-width: 1550px) {
            width: 820px;
            margin-top: 0px;
          }

          .kyMyPageContentRightValueWrap{
            padding: 24px 24px 24px 24px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            .kyMyPageContentRightValueTitle{
              color: rgb(255, 255, 255);
              @include font("smb");
              font-size: 18px;
            }

            .kyMyPageContentRightValueScoreContainer{
              display: flex;
              flex-direction: row;

              .kyMyPageContentRightValueScoreWrap{
                width: 252px;
                border-right: 1px solid #434343;
                &:last-child{
                  border-right: none;
                }
                @media screen and (max-width: 1200px) {
                  width: 200px;
                }

                .kyMyPageContentRightValueScoreCover{
                  padding: 0px 24px 0px 24px;
                  display: flex;
                  flex-direction: column;
                  gap: 4px;

                  .kyMyPageContentRightValueScoreTitle{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: rgb(255, 255, 255, 0.5);
                    @include font("smb");
                    font-size: 16px;
                  }

                  .kyMyPageContentRightValueScore{
                    font-size: 24px;
                    color: #ffffff;
                    @include font("smb");
                  }
                }
                .kyMyPageContentRightValueScoreCover1{
                  padding: 0px 24px 0px 04px;
                  display: flex;
                  flex-direction: column;
                  gap: 4px;

                  .kyMyPageContentRightValueScoreTitle{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    color: rgb(255, 255, 255, 0.5);
                    @include font("smb");
                    font-size: 16px;
                  }

                  .kyMyPageContentRightValueScore{
                    font-size: 24px;
                    color: #ffffff;
                    @include font("smb");
                  }
                }
              }
            }
          }
        }
      }

      .kyMyPageContentLeft{
        position: relative;
        width: 360px;
        bottom: 80px;

        .kyMyPageContentUserInfoSns{
          padding-top: 29px;
          display: flex;
          flex-direction: row;
          gap: 21px;
        }

        .kyMyPageContentUserLocation{
          padding: 16px 8px 24px 0px;
          display: flex;
          flex-direction: column;

          border-bottom: 1px solid #434343;

          .kyMyPageContentUserLocationTitle{
            color: rgb(255, 255, 255, 0.5);
            @include font("smb");
            font-size: 16px;
          }

          .kyMyPageContentUserLocationValue{
            display: flex;
            flex-direction: row;
            gap: 12px;
            height: 44px;
            align-items: center;
            color: rgb(255, 255, 255, 0.7);
            @include font("smb");
            font-size: 16px;
          }
        }

        .kyMyPageContentUserInfoBalanceWrap{
          padding: 16px 8px 24px 0px;
          display: flex;
          flex-direction: column;


          .kyMyPageContentUserInfoBalance{
            color: rgb(255, 255, 255, 0.5);
            @include font("smb");
            font-size: 16px;
          }

          .kyMyPageContentUserInfoBalanceValue{
            padding-top: 26px;
            padding-left: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #434343;
            display: flex;
            flex-direction: column;

            .kyMyPageContentUserInfoBalanceValueValueItems{
              display: flex;
              flex-direction: row;
              gap: 12px;
              height: 44px;
              align-items: center;
              color: rgb(255, 255, 255, 0.7);
              @include font("smb");
              font-size: 16px;
            }
          }
        }

        .bioContainer{
          padding: 16px 8px 24px 8px;
          display: flex;
          flex-direction: column;
          gap: 12px;
          .bioTitle{
            color: rgb(255, 255, 255, 0.5);
            @include font("smb");
            font-size: 16px;
          }

          .bioContent{
            font-size: 16px;
            @include font("smb");
            color: #ffffff;
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
          border-bottom: 1px solid #434343;
        }

        .kyMyPageContentUserProfile{
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .kyMyPageContentUserInfo{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-bottom: 20px;
          border-bottom: 1px solid #434343;
          .kyMyPageContentUserInfoDetails{
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding-top: 40px;

            .kyMyPageContentUserInfoName{
              @include font("bold");
              color: #FFFFFF;
              font-size: 30px;
            }

            .kyMyPageContentUserInfoId{
              @include font("smb");
              font-size: 16px;
              color: rgb(255, 255, 255, 0.7);
              padding-bottom: 32px
            }

          }
            .kyMyPageContentUserInfoPrivateButtonWrap{
              display: flex;
              justify-content: center;
              position: relative;
              
              .privateFinger {
                position: absolute;
                top: 55px;
                @include floating
              }

              .kyMyPageContentUserInfoPrivateButton{
                display: flex;
                flex-direction: row;
                gap: 12px;
                width: 207px;
                height: 44px;
                border-radius: 22px;
                background-color: #50A1FF;
                justify-content: center;
                align-items: center;
                @include font("smb");
                border: none;
                @include shadow;
                cursor: pointer;
              }
            }
        }
      }
    }
  }
  .kyMyPageContentBottom{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
