@import "../../../style/mixin";

.profileDropDown {
  position: fixed;
  width: 272px;
  height: 195px;

  background-color: #141414;
  border-radius: 8px;
  right: 30px;
  top: 100px;
  z-index: 2;

  .profileDropDownWrap {
    display: flex;
    flex-direction: column;
    padding: 18px 16px 18px 16px;

  }
  .profileDropDownContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;
    gap: 12px;
    cursor: pointer;

    .profileDropDownText{
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #FFFFFF;
      @include font("smb");
    }
  }
  .profileDropDownWrap :hover {
    background-color: rgb(24, 24, 24);
  }

  .profileDropDownWrap {
    display: flex;
    flex-direction: column;

    .profileDropDownText {
      display: flex;
      flex-direction: row;
    }
  }
}

.profileDropDownClose {
  display: none;
}
