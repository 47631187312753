@import "../../../../../../style/mixin";

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}


.step1Article {
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-top: 68px;
  @media screen and (max-width: 1290px) {
    grid-template-rows: 1fr 1fr;
  }
  @media screen and (max-width: 1290px) {
    height: 160vh;
  }

  .lottieArea {
    width: 100%;
    .mintPlanet {
      @include font("bold");
      font-size: 48px;
      color: #ffffff;
      position: absolute;
      left: 240px;
      margin-top: 72px;
      @media screen and (max-width: 1290px) {
        left: 5%;
        margin-top: 25px;
      }
    }
  }

  .descWrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10%;
    top: 25%;
    gap: 16px;
    @media screen and (max-width: 1950px) {
      right: 7%;
    }
    @media screen and (max-width: 1290px) {
      left: 10%;
      top: 90%;
    }
    @media screen and (min-height: 1200px) {
      top: 50%;
      right: 10%;
    }

    .step1Desc {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      @include font("smb");
      font-size: 30px;
      line-height: 42px;
      @media screen and (max-width: 1950px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .step1SubDesc {
      display: flex;
      flex-direction: column;
      color: #ffffff;
      font-size: 20px;
      @include font("smb");
      line-height: 32px;
      @media screen and (max-width: 1950px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .startBtnWrap {
    .startBtn {
      width: 98px;
      height: 58px;
      background-color: #7ab7ff;
      border-radius: 27px;
      @include font("smb");
      font-size: 20px;
      position: absolute;
      top: 65%;
      right: 10%;
      border: none;
      cursor: pointer;
      @include shadow;

      @media screen and (max-width: 1290px) {
        top: 100%;
      }
      @media screen and (min-height: 1200px) {
        top: 65%;
        right: 10%;
      }
    }
    .fingerIcon {
      position: absolute;
      top: 75%;
      right: 11%;
      animation: floating 2s ease-in-out infinite;
      @media screen and (max-width: 1290px) {
        top: 110%;
      }
      @media screen and (min-height: 1200px) {
        top: 70%;
        right: 10%;
      }
    }
  }
}
