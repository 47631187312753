@import "../../../../../../style/mixin";

.step5ArticleWrap {
  width: 100%;
  height: 130vh;
  padding-top: 68px;
  @media screen and (max-width: 1450px) {
    height: 200vh;
  }
  @media screen and (min-width: 1450px) {
    height: 170vh;
  }
  .step5Buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 36px;
    padding-bottom: 100px;
    gap: 24px;

    .step5myBtn {
      width: 244px;
      height: 58px;
      color: #50a1ff;

      border: 1px solid #50a1ff;

      background-color: inherit;
      border-radius: 27px;
      @include font("smb");
      font-size: 20px;
      cursor: pointer;
    }

    .step5saleFinger {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .fingerWrap {
        @include floating;
        margin: 0 auto;
      }

      .step5saleBtn {
        width: 244px;
        height: 58px;
        color: #000000;
        @include shadow;
        border: 1px solid #50a1ff;

        background-color: #50a1ff;
        border-radius: 27px;
        @include font("smb");
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .step5PlanetContainer {
    display: flex;
    flex-direction: column;
    gap: 36px;

    .step5Header {
      font-size: 48px;
      color: #ffffff;
      @include font("bold");
      padding-left: 15%;
      padding-top: 3%;
      @media screen and (max-width: 1550px) {
        padding: 50px 0 0 0;
        margin: 0 auto;
      }
    }

    .step5PlanetComponent {
      margin: 0 auto;

      .step5PlanetWrap {
        display: flex;
        justify-content: center;
      }
    }

    .step5User {
      display: flex;
      flex-direction: column;
      text-align: center;

      .step5userInfo {
        display: flex;
        flex-direction: column;
        padding-top: 36px;

        .step5userItem {
          @include font("smb");
          font-size: 30px;
          color: #ffffff;
        }
        .step5userId {
          @include font("mdm");
          font-size: 20px;
          color: rgba(255, 255, 255, 0.7);
        }
        .step5userName {
          @include font("mdm");
          font-size: 20px;
          color: rgba(255, 255, 255, 0.7);
        }
      }
      .step5userPaymentInfo {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .step5paymentInfoWrap {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 16px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          height: 36px;
          .step5infoItem1 {
            @include font("mdm");
            color: rgba(255, 255, 255, 0.5);
          }
          .step5infoItem2 {
            @include font("mdm");
            color: #ffffff;
          }
        }

        .step5paymentInfoWrap2 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 16px;

          .step5infoItem1 {
            @include font("mdm");
            color: rgba(255, 255, 255, 0.5);
          }
          .step5infoItem2 {
            @include font("mdm");
            color: #ffffff;
          }
        }
      }
    }
  }
}
