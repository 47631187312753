@import "../../../../style/mixin";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.kyChatSectionSection {
  width: 100%;
  background-color: rgb(16, 16, 16);
  overflow-y: hidden;

  .kyChatSectionWrap {
    display: flex;
    padding-top: 68px;
    flex-direction: row;
    border: 1px solid #434343;
    justify-content: center;
    align-items: center;

    .kyChatSectionChatRoom {
      width: 100%;
      max-width: 420px;
      border: 1px solid #434343;
      height: calc(120vh);
      .kyChatSectionChatTitleWrap {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .kyChatSectionChatTitle {
          padding: 32px 24px 0px 24px;
          @include font("bold");
          font-size: 30px;
          color: #ffffff;
        }

        .kyChatSectionChatPublicPrivateTab {
          width: 388px;
          margin: 0 auto;
          border: 1px solid #434343;
          border-radius: 8px;
          height: 44px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          @media (max-width: 1550px) {
            width: 200px;
          }


          .kyChatSectionChatPublic {
            border-radius: 8px;
            @include font("smb");
            color: rgba(255, 255, 255, 0.7);
            font-size: 18px;
            width: 191px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 1550px) {
              width: 100px;
            }
          }

          .kyChatSectionChatPrivate {
            border-radius: 8px;
            @include font("smb");
            color: #ffffff;
            font-size: 18px;
            width: 191px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(to bottom right, #7b3dff, #6dddf5);
            @media (max-width: 1550px) {
              width: 100px;
            }
          }
        }
      }
    }

    .kyChatSectionChatInput {
      width: 100%;
      border: 1px solid #434343;
      height: 120vh;
      overflow-y: scroll;

      .kyChatSectionChatInputBodyInput {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: flex-end;
        margin: 0 auto;
        padding-top: 100px;
        @media (max-width: 1650px) {
          padding: 100px 30px 0px 30px;
        }
        @media (max-width: 1550px) {
          padding: 350px 30px 0px 30px;
        }
      }

      .kyChatSectionChatInputHeader {
        width: 100%;
        max-width: 1028px;
        height: 54px;
        background: linear-gradient(to bottom right, #7b3dff, #6dddf5);
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 auto;
        margin-top: 32px;
        border-radius: 8px;
        position:relative;
        .goToFinger{
          position: absolute;
          right: 50%;
          bottom: 100px;
          @include floating
        }
        @media (max-width: 1550px) {
          width: 90%;
        }
        .kyChatSectionChatInputHeaderWrap {
          padding: 0px 16px 0px 16px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;

          .kyChatSectionChatInputHeaderIcon {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 12px;
            @include font("smb");
            color: #ffffff;
          }
        }
      }

      .kyChatSectionChatInputBody {
        width: 100%;
        height: 780px;
        @media (max-width: 1550px) {
          height: 880px;
        }


        .kyChatSectionChatInputBodyDay2 {
          width: 72px;
          height: 36px;
          border: 1px solid #5f5f5f;
          border-radius: 8px;
          color: #ffffff;
          @include font("smb");
          display: flex;
          margin: 0 auto;
          margin-top: 22px;
          justify-content: center;
          align-items: center;
          background-color: #272727;
          @media (max-width: 1550px) {
            margin-bottom: 20px;
          }
        }

        .kyChatSectionChatInputBodyDay2Disabled {
          display: none;
        }

        .kyChatSectionChatInputBodyDay1 {
          width: 72px;
          background-color: #272727;
          height: 36px;
          border: 1px solid #5f5f5f;

          border-radius: 8px;
          color: #ffffff;
          @include font("smb");
          display: flex;
          margin: 0 auto;
          margin-top: 22px;
          justify-content: center;
          align-items: center;
          @media (max-width: 1550px) {
              margin-bottom: 20px;
          }
        }

        .kyChatSectionChatInputBodyChat3Disabled {
          display: none;
        }

        .kyChatSectionChatInputBodyChat3 {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-start;
          margin-bottom: 10px;
          padding-right: 37px;

          .kyChatSectionChatInputBody3Text {
            width: 450px;
            height: 64px;
            border-radius: 8px 8px 0px 8px;
            background-color: #357aea;
            animation: fade-in 0.5s ease-in;
            @media (max-width: 1550px) {
              width: 170px;
              height: 200px;
            }

            .kyChatSectionChatInputBody3TextWrap {
              padding: 8px 16px 8px 16px;
              font-size: 16px;
              @include font("mdm");
              color: #ffffff;
              @media (max-width: 1550px) {
                font-size: 14px;
              }
            }
          }
          .kyChatSectionChatInputBody3TextDisabled {
            display: none;
          }
        }
        .kyChatSectionChatInputBody3Time {
          width: 100%;
          display: flex;
          color: rgba(255, 255, 255, 0.5);
          justify-content: flex-end;
          font-size: 12px;
          padding-right: 37px;
        }
        .kyChatSectionChatInputBody3TimeDisabled {
          display: none;
        }

        .kyChatSectionChatInputBodyChat1 {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-start;
          margin-bottom: 10px;
          padding-right: 37px;
        }

        .kyChatSectionChatInputBodyTextContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 16px;
        }

        .kyChatSectionChatInputBodyTextWrap {
          width: 203px;
          height: 40px;
          border-radius: 8px 8px 0px 8px;
          background-color: #357aea;
          white-space: nowrap;
          animation: fade-in 0.5s ease-in;
          @media (max-width: 1550px) {
            width: 153px;
            height: 60px;
            white-space: normal;
          }
        }
        .kyChatSectionChatInputBodyTextWrapDisabled {
          display: none;
        }
        .kyChatSectionChatInputBodyTextWrap2 {
          width: 450px;
          height: 64px;
          border-radius: 8px 8px 0px 8px;
          background-color: #357aea;
          animation: fade-in 0.5s ease-in;
          @media (max-width: 1550px) {
            width: 193px;
            height: 110px;
            white-space: normal;
          }
        }
        .kyChatSectionChatInputBodyTextWrap2Disabled {
          display: none;
        }

        .kyChatSectionChatInputBodyText {
          padding: 8px 16px 8px 16px;
          font-size: 16px;
          @include font("mdm");
          color: #ffffff;
          @media (max-width: 1550px) {
            font-size: 13px;
          }
        }

        .kyChatSectionChatInputBodyTime {
          width: 100%;
          display: flex;
          color: rgba(255, 255, 255, 0.5);
          justify-content: flex-end;
          font-size: 12px;
          padding-right: 37px;
        }
      }
    }

    .kyChatSectionChatInputBodyChat2 {
      display: flex;
      flex-direction: row;
      gap: 19px;
      padding-left: 37px;
      @media (max-width: 1550px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      .kyChatSectionChatInputBodyUserIcon {
        display: flex;
        align-items: flex-end;
      }
      .kyChatSectionChatInputBodyUserIconDisabled {
        display: none;
      }

      .kyChatSectionChatInputBody2TextContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .kyChatSectionChatInputBody2Chat {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .kyChatSectionChatInputBody2Name {
        @include font("mdm");
        color: #7ab7ff;
        font-size: 14px;
      }

      .kyChatSectionChatInputBody2NameDisabled {
        display: none;
      }

      .kyChatSectionChatInputBodyChatMessage1 {
        background-color: #272727;
        width: 450px;
        height: 88px;
        border-radius: 8px 8px 8px 0px;
        animation: fade-in 0.5s ease-in;
        @media (max-width: 1550px) {
          width: 170px;
          height: 210px;
          white-space: normal;
          font-size: 13px;
        }


        .kyChatSectionChatInputBodyChatMessage1Text {
          padding: 8px 16px 8px 16px;
          color: #ffffff;
          @include font("mdm");
        }
      }

      .kyChatSectionChatInputBodyChatMessage1Disabled {
        display: none;
      }

      .kyChatSectionChatInputBodyChatMessage2 {
        background-color: #272727;
        width: 450px;
        height: 112px;
        border-radius: 8px 8px 8px 0px;
        animation: fade-in 0.5s ease-in;
        @media (max-width: 1550px) {
          width: 170px;
          height: 210px;
          white-space: normal;
          font-size: 13px;
        }

        .kyChatSectionChatInputBodyChatMessage2Text {
          padding: 8px 16px 8px 16px;
          color: #ffffff;
          @include font("mdm");


          & > a {
            text-decoration: underline;
            color: #7ab7ff;
          }
        }
      }

      .kyChatSectionChatInputBodyChatMessage2Disabled {
        display: none;
      }

      .kyChatSectionChatInputBodyChatMessage3 {
        animation: fade-in 0.5s ease-in;
      }
      .kyChatSectionChatInputBodyChatMessage3Disabled {
        display: none;
      }
    }
    .kyChatSectionChatInputBody2Time {
      padding-left: 106px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 12px;
    }
    .kyChatSectionChatInputBody2TimeDisabled {
      display: none;
    }

    .kyChatSectionChatUser {
      width: 100%;
      max-width: 420px;
      border: 1px solid #434343;
      height: calc(120vh);


      .kyChatSectionChatUserContent {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin: 0 auto;
        width: 100%;
        margin-top: 32px;
      }

      .kyChatSectionChatUserHeader {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 32px 32px 32px 16px;

        .kyChatSectionChatUserInfoIcon {
          display: flex;
          justify-content: end;
        }

        .kyChatSectionChatUserInfo {
          display: flex;
          flex-direction: column;

          .kyChatSectionChatUserProfile {
            display: flex;
            justify-content: center;
          }

          .kyChatSectionChatUserInfoText {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .kyChatSectionChatUserTextWrap {
              text-align: center;

              .kyChatSectionChatUserName {
                @include font("smb");
                font-size: 20px;
                line-height: 32px;
                color: #ffffff;
              }

              .kyChatSectionChatUserId {
                @include font("mdm");
                color: rgba(255, 255, 255, 0.7);
                font-size: 14px;
              }
            }

            .kyChatSectionChatUserGoToBtnWrap {
              display: flex;
              justify-content: center;
              position: relative;



              .kyChatSectionChatUserGoToBtn {
                width: 157px;
                height: 36px;
                border-radius: 18px;
                @include font("smb");
                background: #50a1ff;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                font-size: 14px;
                cursor: pointer;
                color: #000000;
                @media (max-width: 1400px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
