@import "../../../../style/mixin";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes floating {
  0% {
    transform: translate(-4px, -4px);
    transform: translateY(0);
  }
  50% {
    transform: translate(4px, 4px);
    transform: translateY(-10);
  }
  100% {
    transform: translate(-4px, -4px);
    transform: translateY(0);
  }
}

@keyframes floating4 {
  0% {
    transform: translate(-7px, -3px);
    transform: translateY(0);
  }
  50% {
    transform: translate(7px, 3px);
    transform: translateY(-30px);
  }
  100% {
    transform: translate(-7px, -3px);
    transform: translateY(0);
  }
}

@keyframes floating2 {
  0% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(-6px);
  }
}

@keyframes floating3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.mobileLandingArticle {
  position: relative;
  width: 100%;
  height: calc(100vh - 200px);

  .kyText {
    &.disabled {
      display: none;
    }
    position: absolute;
    top: 70%;
    left: 5%;
    transform: translateY(-50%);
    color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 37px;
    &.show {
      opacity: 1;
      animation: fade-in 1.5s ease-in-out forwards;
    }

    .kyHeader {
      @include font("twig");
      line-height: 30px;
      font-size: 19px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      width: 280px;
      .mobileKyHeaderItem2 {
        display: flex;
        flex-direction: column;
        :nth-child(1) {
          white-space: nowrap;
          color: #ff93cf;
        }
        @media screen and (max-width: 440px) {
          font-size: 18px;
        }
        :nth-child(2)::before {
          content: "\00a0";
        }
        :nth-child(2) {
          content: "\00a0";
          white-space: nowrap;
        }
      }
    }
    .kyHeaderKo {
      @include font("nanumAcEb");
      line-height: 35px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 19px;
      .mobileKyHeaderItem2 {
        display: flex;
        flex-direction: row;
        :nth-child(1) {
          color: #ff93cf;
        }
        :nth-child(2)::before {
          content: "\00a0";
        }
      }
    }

    .kyPWrapKo {
      @include font("nanumAcEb");
      display: flex;
      flex-direction: column;
      gap: 20px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      .mobileKyPItem {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 22px;
      }
    }

    .kyPWrap {
      @include font("twig");
      display: flex;
      flex-direction: column;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      gap: 12px;
      font-size: 12px;
      line-height: 21px;

      .kyPItem {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .landingTextWrap {
    position: absolute;
    top: 41%;
    left: 5%;
    transform: translateY(-50%);
    color: #ffffff;
    z-index: 1;
    height: 40vh;
    width: 90%;

    &.show {
      opacity: 1;
      animation: fade-in 1.5s ease-in-out forwards;
    }

    &.disabled {
      display: none;
    }

    .mobileLandingTextP {
      @include font("twig");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      padding-bottom: 25px;
      font-size: 10px;
    }

    .mobileLandingTextH1 {
      font-size: 32px;
      line-height: 42px;
      white-space: nowrap;
      width: 200px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include font("twig");
    }
    .mobileLandingTextH2 {
      font-size: 32px;
      line-height: 42px;
      width: 200px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include font("twig");
    }

    .mobileLandingTextLast {
      @include font("twig");
      font-size: 24px;
      position: relative;
      top: 300px;
      text-align: center;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .byWrap {
    &.disabled {
      display: none;
    }
    position: absolute;
    transform: translateY(-50%);
    color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    top: 65%;
    gap: 40px;
    left: 0%;

    &.ko {
      top: 50%;
    }
    padding-left: 5%;
    padding-right: 5%;

    &.show {
      opacity: 1;
      animation: fade-in 1.5s ease-in-out forwards;
      width: 100%;
    }

    .byPHeader {
      font-size: 14px;
      @include font("twig");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: 450px) {
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    .byPHeaderKo {
      font-size: 14px;
      @include font("nanumAcEb");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: 400px) {
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    .byHeaderWrap {
      color: #affffa;
      @include font("twig");
      font-size: 20px;
      line-height: 32px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;
      span {
        color: #ff93cf;
      }
      @media screen and (max-width: 450px) {
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    .byHeaderWrapKo {
      color: #affffa;
      @include font("nanumAcEb");
      font-size: 19px;
      line-height: 32px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;
      span {
        color: #ff93cf;
      }
    }

    .byList {
      font-size: 12px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include font("twig");
      line-height: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap: 15px;
      @media screen and (max-width: 400px) {
        padding-right: 5%;
        padding-left: 5%;
        word-break: break-all;
      }
    }

    .byListKo {
      font-size: 12px;
      @include font("nanumAcEb");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      line-height: 20px;
      z-index: 3;
      display: flex;
      flex-direction: column;
      gap: 15px;
      @media screen and (max-width: 400px) {
        padding-right: 5%;
        padding-left: 5%;
      }
    }
  }

  .mobileMainCharacter {
    position: relative;
    top: 61%;
    transform: translateY(-50%);
    right: 0;

    > img:first-child {
      height: calc(90vh);
      object-fit: cover;
      user-select: none;
      position: relative;
      top: 50px;
    }
    > img:nth-child(2) {
      top: 30%;
      right: 13%;
      position: absolute;
      width: 280px;
      animation-name: floating4;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
    > img:nth-child(3) {
      top: 25%;
      right: 65%;
      position: absolute;
      width: 30%;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    > img:nth-child(4) {
      top: 70%;
      bottom: 0;
      right: 85%;
      position: absolute;
      width: 8%;
      animation-name: floating2;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
    > img:nth-child(5) {
      top: 80%;
      bottom: 0;
      right: 0%;
      position: absolute;
      width: 10%;
      animation-name: floating3;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
    > img:nth-child(6) {
      top: 20%;
      bottom: 0;
      right: 12%;
      position: absolute;
      width: 7%;
      animation-name: floating3;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

  .mobileKyImage {
    position: relative;
    top: 15%;
    transform: translateY(-50%);
    right: 0;

    //> img:first-child {
    //  width: 100%;
    //  height: calc(70vh);
    //  object-fit: cover;
    //  user-select: none;
    //}
    > img:nth-child(1) {
      top: 0%;
      right: 5%;
      position: absolute;
      width: 245px;
      animation-name: floating4;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
    > img:nth-child(2) {
      right: 55%;
      position: absolute;
      width: 25%;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    > img:nth-child(3) {
      margin-top: 150px;
      right: 80%;
      position: absolute;
      width: 10%;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }

    > img:nth-child(4) {
      margin-top: 300px;
      right: 10%;
      position: absolute;
      width: 10%;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }

  .byImage {
    position: relative;
    width: 100%;
    height: 890px;
    user-select: none;
    background-image: url("../../../../public/image/mobileByback.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .byImage img:first-child {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 65%;
    object-fit: cover;
    user-select: none;
  }

  .byImage img:nth-child(2) {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    height: auto;
    z-index: 0;
    user-select: none;
    transform: translateZ(0); /* 이 부분 추가 */
  }

  .byImage img:nth-child(3) {
    top: 80%;
    bottom: 0;
    right: 65%;
    position: absolute;
    width: 16%;
    animation-name: floating3;
    animation-duration: 6s;
    animation-iteration-count: infinite;
  }
  .byImage img:nth-child(4) {
    top: 60%;
    bottom: 0;
    right: 30%;
    position: absolute;
    width: 5%;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
  .byImage img:nth-child(5) {
    top: 50%;
    bottom: 0;
    right: 90%;
    position: absolute;
    width: 20%;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .byCharacter {
    &.disabled {
      opacity: 0;
    }
  }
}
