@import "../../../../style/mixin";


@keyframes floatAnimation {
  0% {
    transform: translate(0%, 10%);
  }
  50% {
    transform: translate(0%, -5%);
  }
  100% {
    transform: translate(0%, 10%);
  }
}



.worldArticle{
  width: 100%;
  height: 564px;
  padding-top: 68px;
  @media screen and (max-width: 1200px) {
      height: 750px;
  }
  @media screen and (max-width: 900px) {
    height: 850px;
  }
  background-image: url('../../../../public/image/worldBack.jpeg');
  background-size: cover;
  border-bottom: 2px solid rgb(37,37,37);
  display: flex;
  justify-content: center;
  align-items: center;

  .worldWrap{
    padding: 72px 240px 72px 240px;
    display: flex;
    flex-direction: row;
    gap: 64px;

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 72px 100px 72px 100px;
      display: flex;
      gap: 24px;
      .worldHeaderImage {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding: 72px 35px 72px 35px;
      display: flex;
      gap: 24px;
      .worldHeaderImage {
        margin: 0 auto;
      }
    }


    .worldHeaderText{
      display: flex;
      flex-direction: column;
      gap: 102px;
      padding-top: 32px;
      @media screen and (max-width: 1200px) {
        flex-direction: row;
      }


      .worldHeaderTexContainer{
        display: flex;
        flex-direction: column;
        gap: 16px;

        .worldHeaderTextTitle{
          color: #ffffff;
          @include font("bold");
          font-size: 48px;
          line-height: 58px;
          @media screen and (max-width: 1600px) {
            font-size: 30px;
            line-height: 40px;
          }
        }

        .worldHeaderTextDesc{
          color: #ffffff;
          @include font("mdm");
          font-size: 20px;
          @media screen and (max-width: 1600px) {
            font-size: 18px;
          }
        }
      }
      
      .worldHeaderBtnWrap{
        display: flex;
        justify-content: end;
        flex-direction: row;
        gap: 16px;

        .worldHeaderBtn{
          width: 162px;
          cursor: pointer;
          border-radius: 27px;
          height: 58px;
          background-color: #000000;
          color: #50A1FF;
          display: flex;
          flex-direction: row;
          gap: 16px;
          justify-content: center;
          align-items: center;
          border: 1px solid #50A1FF;
          @include font("smb");
        }

        .worldHeaderBtn2{
          width: 162px;
          border-radius: 27px;
          height: 58px;
          background-color: #7AB7FF;
          @include font("smb");
          cursor: pointer;
          border: none;
        }
      }
    }
  }
}

.eventArticle{
  width: 100%;
  background-color: #141414;

  .eventWrap{
    padding: 72px 240px 72px 240px;
    display: flex;

    .eventTitle{
      color: #ffffff;
      @include font("bold");
      font-size: 30px;
      padding-bottom: 25px;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .eventImageWrap{
      display: flex;
      flex-direction: row;
      gap: 32px;
      @media screen and (max-width: 1350px) {
        flex-wrap: wrap;
        justify-content: center;
      }


      .imageContainer{
        width: 400px;
        height: 525px;
        background-image: url('../../../../public/image/luluBack.jpeg');
        background-size: cover;
        background-position: center;
        @media screen and (max-width: 1450px) {
          width: 300px;
          height: 370px;
        }
        @media screen and (max-width: 1350px) {
          width: 350px;
          height: 400px;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .imageContainer img {
        position: absolute;
        margin-top: 120px;
        transition: transform 1.5s;
        width: 450px;
        height: 450px;
        @media screen and (max-width: 1450px) {
          width: 300px;
          height: 370px;
          margin-top: 60px;
        }
      }
      .imageContainer2{
        width: 400px;
        height: 525px;
        background-image: url('../../../../public/image/binocularsBack.jpeg');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          transform: translate(-50%, -50%);
          animation: floatAnimation 4s infinite linear;
        }
        @media screen and (max-width: 1450px) {
          width: 300px;
          height: 370px;
        }
        @media screen and (max-width: 1350px) {
          width: 350px;
          height: 400px;
        }
      }
      .imageContainer3{
        width: 576px;
        height: 525px;
        position: relative;
        background-image: url('../../../../public/image/nightSky.jpeg');
        background-size: cover;
        background-position: center;
        @media screen and (max-width: 1350px) {
          width: 750px;
          height: 700px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .imageContainer3 img {
        position: absolute;
        left: 0;
        bottom: 300px;
        transition: transform 1.5s;
        @media screen and (max-width: 1350px) {
          bottom: 400px;
        }

      }
      .imageContainer3 .overay img {
        transform: scale(1);
        transform-origin: left bottom;
      }

      .imageContainer3 .overay:hover img {
        transform: scale(1.3);
        transform-origin: left bottom;
      }
    }
  }
}