@import "../../../../style/mixin";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    display: none;
    transform: translateX(100%);
  }
}

.mobileHeaderContainer{
  height: 100%;
  width: 100%;

  .mobileHeaderWrap{
    display: flex;
    height: 44px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    padding-left: 5%;
    padding-right: 5%;

    .mobileNav{
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
    }
  }

  .mobileLanguageMenu {

    z-index: 3;
    height: 100px;
    width: 150px;
    border-radius: 5px;
    border: 1px solid #d2d2d2;
    position: absolute;
    right: 35px;
    position: absolute;


    .isSelectedItem {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 16px;
    }
    .isNotSelected {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      font-size: 16px;
      background-color: rgb(214 212 222 / 95%);
    }

    .isKorSelectedItem {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      border-bottom-left-radius: 5px;
      font-size: 16px;
      border-bottom-right-radius: 5px;
    }
    .isKorNotSelectedItem {
      @include languageMenuStyle;
      @include font("nanumAcEb");
      font-size: 16px;
      background-color: rgb(214 212 222 / 95%);
    }
  }

  .sideMenu{
    width: 100%;
    background-color: rgb(0,0,0, 50%);
    height: calc(100vh + 50px);
    position: relative;
    bottom: 50px;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

    &.open {
      /* 추가된 스타일 */
      z-index: 10;
      animation: fadeIn 0.5s ease-in-out forwards;
      .sideMenuContainer {
        width: 80%;
        background-color: #ffffff;
        float: right;
        height: calc(100vh + 50px);
        z-index: 10;
      }
      .closeIcon {
        text-align: right;
        padding-right: 20px;
        padding-top: 30px;
      }
      .sideMenuNav {
        padding-left: 30px;
        padding-top: 30px;
        @include font("nanumAcEb");

        .sideMenuItem {
          height: 63px;
          cursor: pointer;
        }
      }
    }


    &.close .sideMenuContainer,
    &.close .closeIcon,
    &.close .sideMenuNav {
      display: none;
    }
    &.close {
      animation: fadeOut 0.5s ease-in-out forwards;
      background-color: rgb(0,0,0, 0%);
    }

    &.ready {
      display: none;
    }
  }
}