@import "../../../style/mixin";

.categoryCardWrap{
  width: 336px;
  height: 420px;
  background-color: rgb(29,29,29);
  border-radius: 8px;

  .categoryCardContainer{
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .categoryCardText{
      display: flex;
      flex-direction: column;
      gap: 24px;

      .categoryCardTextItem{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .categoryCardTextItemTitle{
          color: #ffffff;
          @include font("bold");
          font-size: 24px;
        }
        .categoryCardTextItemDesc{
          color: #ffffff;
          @include font("lgt");
          font-size: 16px;
        }
      }

      .categoryCardTextAuthor{
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: end;

        .categoryCardTextAuthorName{
          @include font("lgt");
          color: rgba(255,255,255, 0.5);
          font-size: 16px;
        }
      }
    }
  }
}