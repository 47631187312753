@import "../../../../style/mixin";

.kyPlanetInfoBanner{
    width: 100%;
    height: 280px;
    background-image: url("../../../../public/image/planetInfoBanner.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
    .kyPlanetInfoWrap{
        padding: 0px 240px 0px 240px;
        background-color: #161616;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .kyPlanetInfoContainer{

        .kyPlanetInfoPlanetProfile{
            padding-left: 50px;
            position: relative;
            bottom: 75px;
        }

        .kyPlanetInfoPlanetProfile img {
            border-radius: 8px;
        }



        .kyPlanetInfoPlanetInfoHeader{
            display: flex;
            flex-direction: row;
            padding: 16px 0px 16px 0px;
            justify-content: space-between;
            align-items: center;

            .kyPlanetInfoPlanetInfoTitle{
                color: #ffffff;
                @include font("bold");
                font-size: 36px;
            }

            .kyPlanetInfoPlanetInfoSns{
                display: flex;
                flex-direction: row;
                gap: 20px;
            }
        }

        .kyPlanetInfoUserInfo{
            display: flex;
            flex-direction: column;
            gap: 16px;
                position: relative;
            .kyPlanetInfoUserInfoFinger{
                position: absolute;
                top: 60px;
                @include floating;
            }

            .kyPlanetInfoUserInfoDesc{
                color: rgba(255, 255, 255, 0.7);
                @include font("smb");
                line-height: 30px;
                font-size: 18px;
            }

            .kyPlanetInfoUserInfoDetails{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                width: 140px;
                border-radius: 22px;
                @include shadow;
                cursor: pointer;


                .ktPlanetInfoUserInfoName{
                    @include font("smb");
                    color: #ffffff;
                    font-size: 18px;
                }

                .kyPlanetInfoUserInfoProfile{
                    width: 44px;
                    height: 44px;
                }

                .kyPlanetInfoUserInfoProfile img {
                    border-radius: 100%;
                }
            }
        }

        .kyPlanetInfoUserInfoWalletInfo{
            padding-top: 24px;
            display: flex;
            flex-direction: row;
            gap: 32px;

            .kyPlanetInfoUserInfoWalletInfoDetails{
                display: flex;
                flex-direction: column;
                gap: 8px;

                .kyPlanetInfoUserInfoWalletInfoValue{
                    @include font("bold");
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.9);
                }

                .kyPlanetInfoUserInfoWalletInfoTitle{
                    font-size: 14px;
                    @include font("mdm");
                    color: rgba(255, 255, 255, 0.7);
                }
            }
        }

        }
    }
