@import "../../../../../../style/mixin";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.step2ArticleWrap {
  height: 100vh;
  width: 100%;
  max-width: 1680px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 100px;
  margin: 0 auto;
  padding-top: 68px;
  @media screen and (max-width: 1300px) {
    grid-template-rows: 1fr 1fr;
    gap: 15px;
  }
  @media screen and (max-width: 1450px) {
    height: 180vh;
  }
  @media screen and (min-width: 1450px) {
    height: 120vh;
  }

  .step2PlanetWrap {
    width: 480px;
    margin-left: 15%;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }

    .colorPlanet {
      width: 100%;
      padding-top: 60px;
      opacity: 0;
      animation: fadeIn 1s ease-in-out forwards;

      .planetName {
        @include font("smb");
        color: #ffffff;
        font-size: 30px;
        text-align: center;
      }
      .planetColor {
        color: #ffffffb2;
        font-size: 20px;
        text-align: center;
        padding-top: 10px;
      }
    }
    .silverPlanetComponent {
      width: 100%;
      padding-top: 60px;
      .planetName {
        @include font("smb");
        color: #ffffff;
        font-size: 30px;
        text-align: center;
      }
      .planetColor {
        color: #ffffffb2;
        font-size: 20px;
        text-align: center;
        padding-top: 10px;
      }
    }

    .mintPlanet {
      @include font("bold");
      white-space: nowrap;
      font-size: 48px;
      color: #ffffff;
      margin-left: 15%;
      padding-top: 72px;
    }
  }

  .planetDescWrap {
    padding-top: 218px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    max-width: 700px;

    .planetDescHeader {
      font-size: 30px;
      @include font("bold");
      color: #ffffff;
    }
    .planetDesc {
      font-size: 20px;
      @include font("smb");
      color: #ffffff;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .fingerWrap {
      @include floating;
    }

    .colorPickerWrap {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .colorPicker {
        display: flex;
        flex-direction: column;
        .colorText {
          @include font("smb");
          font-size: 16px;
          color: #ffffffb2;
          padding-bottom: 10px;
        }

        .inputWrap {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          width: 240px;
          border: 1px solid #434343;
          border-radius: 8px;
          height: 44px;
          background-color: #272727;
          @include shadow;
        }
        .inputWrap2 {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          width: 240px;
          border: 1px solid #434343;
          border-radius: 8px;
          height: 44px;
          background-color: #272727;

        }
          .inputSharp {
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffffb2;
          }
          .colorInput {
            background-color: inherit;
            border: none;
            outline: none;
            color: #878787;
            cursor: pointer;
          }
      }

      .colorPickerIcon {
        margin-top: 34.5px;
        height: 44px;
        width: 44px;
        border: 1px solid #434343;
        border-radius: 8px;
        background-color: #272727;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .btnFingerWrap {
    .nextBtn {
      border-radius: 27px;
      width: 94px;
      height: 58px;
      background-color: #7ab7ff;
      border: 1px solid #7ab7ff;
      @include font("smb");
      font-size: 20px;
      position: absolute;
      right: 10%;
      top: 84%;
      cursor: pointer;
      @media screen and (max-width: 1600px) {
        top: 94%;
      }
      @media screen and (max-width: 1250px) {
        top: 129%;
        right: 5%;
      }
      @include shadow;
      @media screen and (min-height: 1200px) {
        top: 49%;
        right: 10%;
      }
    }

    .finger2Wrap {
      position: absolute;
      right: 10%;
      top: 90%;
      @include floating;
      @media screen and (max-width: 1600px) {
        top: 100%;
      }
      @media screen and (max-width: 1250px) {
        top: 140%;
        right: 5%;
      }
      @media screen and (min-height: 1250px) {
        top: 55%;
        right: 10%;
      }
    }

    .nextBtnDisabled {
      border-radius: 27px;
      width: 94px;
      height: 58px;
      color: rgba(114, 115, 118);
      background-color: rgba(61, 62, 66);
      border: none;
      @include font("smb");
      font-size: 20px;
      position: absolute;
      right: 10%;
      top: 84%;
      cursor: pointer;
      @media screen and (max-width: 1600px) {
        top: 94%;
      }
      @media screen and (max-width: 1250px) {
        top: 140%;
        right: 5%;
      }
      @media screen and (min-height: 1200px) {
        top: 49%;
        right: 10%;
      }
    }
  }
}

.confirmModalContainer {
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .modalTextWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .modalHeader {
      @include font("bold");
      color: #ffffff;
      font-size: 20px;
      text-align: center;
    }

    .modalDescWrap {
      text-align: center;
      .modalDescription {
        @include font("smb");
        color: #ffffff;
      }
    }
  }

  .chainInfoWrap {
    background-color: #141414;
    border-radius: 8px;
    border: 1px solid #434343;
    .chainInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px 16px 16px 16px;
      .polygonIcon {
        display: flex;
        felx-direction: row;
        gap: 10px;

        .polygonInfoWrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          color: #ffffff;
          :nth-child(2) {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      .connectedButton {
        width: 92px;
        height: 28px;
        background-color: #0e3619;
        border: 1px solid #166434;
        border-radius: 4px;
        color: #f2fdf5;
        @include font("smb");
        font-size: 12px;
      }
    }
  }

  .priceInfoWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .priceInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 36px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .feeInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 36px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    .paymentInfoContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 36px;
    }
    .infoItem1 {
      @include font("mdm");
      font-size: 16px;
      color: #cccccc80;
    }

    .infoItem2 {
    }
    @include font("mdm");
    font-size: 16px;
    color: #ffffff;
  }
    .finger3Wrap{
      position: absolute;
      @include floating2;
      top: 80%;
      right: -25%;
      transform: rotate(-90deg);
      @media screen and (min-height: 1250px) {
        top: 100%;
        right: 20%;
        @include floating;
      }
    }

  .modalBtnWrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    position: relative;

    .cancelBtn {
      width: 160px;
      height: 44px;
      color: #ffffff;
      background-color: inherit;
      border: 1px solid #434343;
      border-radius: 22px;
      cursor: pointer;
      @include font("smb");
    }

    .confirmBtn {
      width: 160px;
      height: 44px;
      color: #000000;
      background-color: #7ab7ff;
      border: 1px solid #7ab7ff;
      border-radius: 22px;
      cursor: pointer;
      @include font("smb");
      @include shadow;
    }
  }
}

.completeModalContainer {
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .completeHeaderWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-size: 24px;
    @include font("bold");
    color: #ffffff;
  }

  .bodyWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .txWrap1 {
      display: flex;
      justify-content: space-between;
      height: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .txWrap2 {
      display: flex;
      justify-content: space-between;
    }
    .txItem1 {
      color: rgba(255, 255, 255, 0.5);
    }
    .txItem2 {
      color: #ffffff;
    }
  }
}

.loaderWrap {
  padding: 24px 24px 24px 24px;
  font-size: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
