@import "../../../../style/mixin";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes floating {
  0% {
    transform: translate(-4px, -4px);
    transform: translateY(0);
  }
  50% {
    transform: translate(4px, 4px);
    transform: translateY(-10);
  }
  100% {
    transform: translate(-4px, -4px);
    transform: translateY(0);
  }
}

@keyframes floating4 {
  0% {
    transform: translate(-7px, -3px);
    transform: translateY(0);
  }
  50% {
    transform: translate(7px, 3px);
    transform: translateY(-30px);
  }
  100% {
    transform: translate(-7px, -3px);
    transform: translateY(0);
  }
}

@keyframes floating2 {
  0% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(6px);
  }
  100% {
    transform: translateX(-6px);
  }
}

@keyframes floating3 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.landingArticle {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);

  .kyText {
    &.disabled {
      display: none;
    }
    position: absolute;
    top: 58%;
    left: 20%;
    transform: translateY(-50%);
    color: #ffffff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 37px;
    &.show {
      opacity: 1;
      animation: fade-in 1.5s ease-in-out forwards;

    }
    @media screen and (max-width: 1550px) {
      left: 195px;
    }

    .kyHeader {
      @include font("twig");
      line-height: 40px;
      font-size: 32px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: 1550px) {
        font-size: 30px;
      }
      .kyHeaderItem2 {
        display: flex;
        flex-direction: row;
        :nth-child(1) {
          color: #ff93cf;
        }
        :nth-child(2)::before {
          content: "\00a0";
        }
      }
    }
    .kyHeaderKo {
      @include font("nanumAcEb");
      line-height: 42px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 34px;
      @media screen and (max-width: 1550px) {
        font-size: 32px;
      }
      .kyHeaderItem2 {
        display: flex;
        flex-direction: row;
        :nth-child(1) {
          color: #ff93cf;
        }
      }
    }

    .kyPWrapKo {
      @include font("nanumAcEb");
      display: flex;
      flex-direction: column;
      gap: 47px;
      @media screen and (max-width: 1550px) {
        gap: 17px;
      }
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      .kyPItem {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        @media screen and (max-width: 1550px) {
          font-size: 18px;
        }
        line-height: 32px;
      }
    }

    .kyPWrap {
      @include font("twig");
      display: flex;
      flex-direction: column;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      gap: 47px;
      @media screen and (max-width: 1550px) {
        gap: 17px;
      }
      .kyPItem {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        line-height: 32px;
      }
    }
  }

  .landingTextWrap {
    position: absolute;
    top: 60%;
    left: 20%;
    transform: translateY(-50%);
    color: #ffffff;
    z-index: 1;
    height: 40vh;

    &.show {
      opacity: 1;
      animation: fade-in 1.5s ease-in-out forwards;
    }

    &.disabled {
      display: none;
    }
    @media screen and (max-width: 1550px) {
      left: 195px;
    }

    .landingTextP {
      @include font("twig");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      padding-bottom: 41px;
      font-size: 20px;
    }

    .landingTextH1 {
      font-size: 60px;
      line-height: 72px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include font("twig");
    }

    .landingTextLast {
      @include font("twig");
      font-size: 30px;
      padding-top: 100px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media screen and (max-width: 1100px) {
      .landingTextP {
        @include font("twig");
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 41px;
        font-size: 15px;
      }

      .landingTextH1 {
        font-size: 42px;
        line-height: 72px;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        @include font("twig");
      }

      .landingTextLast {
        @include font("twig");
        font-size: 30px;
        padding-top: 100px;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    @media screen and (max-width: 850px) {
      left: 10%;
    }
  }

  .byWrap {
    &.disabled {
      display: none;
    }
    position: absolute;
    top: 61%;
    left: 20%;
    transform: translateY(-50%);
    color: #ffffff;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 47px;

    &.show {
      opacity: 1;
      animation: fade-in 1.5s ease-in-out forwards;
    }
    @media screen and (max-width: 1550px) {
      left: 195px;
      gap: 37px;
    }

    .byHeaderWrap {
      color: #affffa;
      @include font("twig");
      font-size: 32px;
      @media screen and (max-width: 1550px) {
        font-size: 30px;
      }
      line-height: 42px;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      width: 60%;
    }

    .byPHeader {
      font-size: 20px;
      @media screen and (max-width: 1550px) {
        font-size: 18px;
      }
      @include font("twig");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .byPHeaderKo {
      font-size: 20px;
      @media screen and (max-width: 1550px) {
        font-size: 18px;
      }
      @include font("nanumAcEb");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .byHeaderWrapKo {
      color: #affffa;
      @include font("nanumAcEb");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 32px;
      @media screen and (max-width: 1550px) {
        font-size: 30px;
      }
      line-height: 42px;
    }

    .byList {
      font-size: 20px;
      @media screen and (max-width: 1550px) {
        font-size: 18px;
      }
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      @include font("twig");
      line-height: 42px;
    }

    .byListKo {
      font-size: 20px;
      @media screen and (max-width: 1550px) {
        font-size: 18px;
      }
      @include font("nanumAcEb");
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      line-height: 42px;
    }
  }

  .mainCharacter {
    position: fixed;
    top: 52%;
    transform: translateY(-50%);
    right: 0;
    @media screen and (width: 1127px) {
      width: 100%;
      max-width: 1127px;
    }

    > img:first-child {
      height: calc(95vh);
      object-fit: cover;
      user-select: none;
    }
    > img:nth-child(2) {
      top: 28%;
      bottom: 0;
      right: 28%;
      position: absolute;
      width: 480px;
      animation-name: floating4;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      @media screen and (max-width: 1100px) {
        width: 400px;
        right: 10%;
      }
    }
    > img:nth-child(3) {
      top: 15%;
      bottom: 0;
      right: 54%;
      position: absolute;
      width: 22%;
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
    > img:nth-child(4) {
      top: 70%;
      bottom: 0;
      right: 82%;
      position: absolute;
      width: 8%;
      animation-name: floating2;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
    > img:nth-child(5) {
      top: 80%;
      bottom: 0;
      right: 12%;
      position: absolute;
      width: 10%;
      animation-name: floating3;
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

  @media screen and (min-width: 1950px) {
    .mainCharacter {
      right: 250px;
    }
  }

  .byImage {
    position: relative;
    width: 100%;
    height: 100vh;
    user-select: none;
    background-image: url("../../../../public/image/bybg.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .byImage img:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }

  .byImage img:nth-child(2) {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 650px;
    height: auto;
    z-index: 1;
    user-select: none;
  }
  .byImage img:nth-child(3) {
    top: 65%;
    bottom: 0;
    right: 30%;
    position: absolute;
    width: 16%;
    animation-name: floating3;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    @media screen and (min-width: 1550px) {
      top: 65%;
    }
  }
  .byImage img:nth-child(4) {
    top: 20%;
    bottom: 0;
    right: 3%;
    position: absolute;
    width: 5%;
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  .byCharacter {
    &.disabled {
      opacity: 0;
    }
  }
}
