@import "../../../../style/mixin";


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spaceSection{
  width: 100%;
  height: calc(110vh);
  background-image: url("../../../../public/image/kydemoback.jpeg");
  position: relative; /* 추가 */
  overflow: hidden;

  .commonHeaderWrap{
    position: absolute;
    z-index: 3;
    width: 100%;
  }

  .lottieBack{
    position: fixed;
    top: 50%;
    left: 46%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 0;
  }
  .connectedInfo::-webkit-scrollbar{
    width: 5px;
  }
  .connectedInfo::-webkit-scrollbar-thumb {
    height: 30%;
    background:  rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }

  .connectedInfo{
    position: absolute;
    width: 372px;
    height: 85%;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    top: 10%;
    right: 1%;
    border-radius: 8px;
    overflow-y: auto;
    z-index: 2;
    @media (max-width: 1200px) {
      height: 70%;
      top: 10%;
      right: 1%;
    }


    .connectedInfoWrap{
      display: flex;
      flex-direction: column;
      gap: 16px;

      .connectedInfoBody{
        width: 100%;
        display: flex;
        flex-direction: column;

        .connectedInfoBodyItem{
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 86px;
          gap: 16px;
          padding-left: 16px;
          align-items: center;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &:nth-child(1){
            border-top: none;
          }

          .rank{
            font-size: 16px;
            color: rgba(255, 255, 255, 0.7);
            @include font("mdm");
          }

          .userRankInfo{
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            .userRankImg img{
              border-radius: 100%;
              width: 44px;
              height: 44px;
            }
            .userRankInfoDetails{
              display: flex;
              flex-direction: column;
              gap: 4px;

              .userRankId{
                font-size: 18px;
                color: #ffffff;
                @include font("smb");
              }

              .userRankPercent{
                font-size: 16px;
                color: rgba(255, 255, 255, 0.5);
                @include font("smb");
              }
            }
          }
        }

      }

      .connectedInfoHeaderWrap{
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 32px 16px 0px 16px;

        .connectedInfoHeader {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.5);
          @include font("smb");
        }
        .connectedInfoHeaderDesc {
          font-size: 24px;
          color: #ffffff;
          @include font("bold");
        }
      }
    }
  }

  .SpaceHeaderWrap{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 10%;
    left: 5%;
    gap: 16px;
    z-index: 2;
    .SpaceHeader{
      color: #ffffff;
      font-size: 36px;
      @include font("bold");
    }

    .SpaceHeaderDesc{
      font-size: 18px;
      @include font("smb");
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .planetContainer{
    width: 100%;
    max-width: 1912px;
    display: flex;
    cursor: pointer;
    margin: 0 auto;
    height: 90%;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .planet{
    height: 0%;

    //z-index: 1;
    .planetUserInfoSavvyWrapNot{
      width: 100%;
      height: 56px;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(10px);
      margin: 0 auto;
      position: relative;
      bottom: 10px;
      right: 40px;
      border-radius: 8px;
      border: 1px solid #878787;
      display: flex;
      align-items: center;
      @include shadow;
      .planetFinger{
        position: absolute;
        left: 70px;
        top: 70px;
        @include floating;
      }

      .planetUserInfoContainerNot{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px 8px 16px;
        gap: 8px;
        cursor: pointer;

        .planetUserInfoDetails{
          display: flex;
          flex-direction: column;
          gap: 8px;

          .planet1UserInfoName{
            @include font("bold");
            font-size: 14px;
            color: #ffffff;
          }
          .planet2UserInfoId{
            @include font("smb");
            font-size: 18px;
            color: rgba(255, 255, 255, 0.5);
          }
        }

        .planetUserInfoImage img {
          border-radius: 100%;
          width: 40px;
          height: 40px;
        }
      }
    }

    .planetUserInfoWrapNot{
      width: 100%;

      height: 56px;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(10px);
      margin: 0 auto;
      position: relative;
      bottom: 10px;
      right: 25px;
      border-radius: 8px;
      border: 1px solid #878787;
      display: flex;
      align-items: center;

      .planetUserInfoContainerNot{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px 8px 16px;
        gap: 8px;
        cursor: pointer;

        .planetUserInfoDetails{
          display: flex;
          flex-direction: column;
          gap: 8px;

          .planet1UserInfoName{
            @include font("bold");
            font-size: 18px;
            color: #ffffff;
          }
          .planet2UserInfoId{
            @include font("smb");
            font-size: 18px;
            color: rgba(255, 255, 255, 0.5);
          }
        }

        .planetUserInfoImage img {
          border-radius: 100%;
          width: 40px;
          height: 40px;
        }
      }
    }


    .planetUserInfoWrap{
      width: 100%;
      max-width: 312px;

      background-color: rgba(255, 255, 255, 0.35);
      backdrop-filter: blur(20px);
      margin: 0 auto;
      position: relative;
      right: 8px;
      bottom: 15px;
      border-radius: 8px;
      border: 1px solid #878787;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      padding: 8px 16px 8px 16px;
      .planetFinger{
        position: absolute;
        left: 130px;
        top: 130px;
        @include floating;
      }
      
      .planetUserInfoButton{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 264px;
        height: 44px;
        background-color: #ffffff;
        border-radius:22px;
        border: 1px solid #71717A;
        justify-content: center;
        gap: 18px;
        cursor: pointer;
        @include font("smb");
        @include shadow;
      }

      .planetUserInfoContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        position: relative;


        .planetUserInfoDetails{
          display: flex;
          flex-direction: column;
          gap: 8px;

          .planet1UserInfoName{
            @include font("bold");
            font-size: 18px;
            color: #ffffff;
          }
          .planet2UserInfoId{
            @include font("smb");
            font-size: 18px;
            color: rgba(255, 255, 255, 0.5);
          }
        }

      .planetUserInfoImage img {
        border-radius: 100%;
      }
    }

      }

  }
}